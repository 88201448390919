.site-header {
  background-color: rgba(0, 0, 0, .85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);

  a {
    color: #999;
    transition: ease-in-out color .15s;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

.modal-confirm {
  text-align: center;

  .btn {
    margin: 10px 5px 0;
  }
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}